.about__container {
    grid-template-columns:repeat(2, 1fr);
    align-items: center;
    column-gap: 4rem;
}

.about__img {
    width: 370px;
    border-radius: 1.5rem;
    justify-self: center;
}

.about__description {
    padding: 0 4rem 0 0;
    margin-bottom: var(--mb-2-5);
    white-space: pre-wrap;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .about__container {
        grid-template-columns: 1fr;
        row-gap: 2.5rem;
    }

    .about__img {
        width: 250px;
    }

    .about__data {
        text-align: center;
    }

    .about__description {
        padding: 0 5rem;
        margin-bottom: 2rem;

    }
}

/* For medium devices */
@media screen and (max-width: 576px) {
    .about__description {
        padding: 0 ;
    }
}